import React from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import { NextPageWithLayout } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import { LoginForm } from "@grape/ui-login";
import { signIn } from "@grape/ui-auth";
import { withAuthRequired } from "auth/withAuthRequired";

const LoginPage: NextPageWithLayout = () => {
  const router = useRouter();
  const { redirect } = router.query;
  const callbackUrl = typeof redirect === "string" ? redirect : "/dashboard";

  const onEmailSubmit: (typeof LoginForm.defaultProps)["onEmailSubmit"] = ({
    email,
  }) => signIn("email", { email, callbackUrl });

  const onGoogleAuthClick: (typeof LoginForm.defaultProps)["onGoogleAuthClick"] =
    () => signIn("google", { callbackUrl });

  return (
    <div className="flex flex-col items-center justify-center min-h-screen py-2">
      <Head>
        <title>🍇 | Sign In</title>
      </Head>

      <LoginForm
        hasFormError={!!router.query.error}
        onEmailSubmit={onEmailSubmit}
        onGoogleAuthClick={onGoogleAuthClick}
      />
    </div>
  );
};

export const getServerSideProps = withAuthRequired({
  permissions: {
    isAuthenticatedAdmin: false,
  },
  async getServerSideProps({ locale, session }) {
    const language = session?.user.language || locale;

    return {
      props: {
        ...(await serverSideTranslations(language, ["common"])),
      },
    };
  },
});

export default LoginPage;

import {
  FieldPath,
  RegisterOptions,
  useForm as useReactHookForm,
  UseFormProps,
  get,
  PathValue,
} from "react-hook-form";

export type UseFormReturn<T> = ReturnType<typeof useForm<T>>;

export function useForm<T>(props: UseFormProps<T> = {}) {
  const { register, setValue, watch, formState, ...rest } =
    useReactHookForm<T>(props);

  const { errors } = formState;

  function registerOverride<TFieldName extends FieldPath<T>>(
    name: TFieldName,
    options?: RegisterOptions<T, FieldPath<T>>
  ) {
    return {
      id: name,
      errorMessage: get(errors, name)?.message,
      ...register(name, options),
    };
  }

  function registerNonDomElement<TFieldName extends FieldPath<T>>(
    name: TFieldName,
    options?: RegisterOptions<T, FieldPath<T>>
  ) {
    const { onChange, ...otherProps } = registerOverride(name, options);
    return {
      ...otherProps,
      value: watch(name),
      onChange: (val: PathValue<T, TFieldName>) =>
        setValue(name, val, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        }),
    };
  }

  return {
    formState,
    register: registerOverride,
    setValue,
    watch,
    registerNonDomElement,
    ...rest,
  };
}

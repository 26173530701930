import React, { FC, useState } from "react";
import { useTranslation } from "next-i18next";

import {
  Box,
  Button,
  Image,
  InputLabelled,
  Typography,
} from "@grape/ui-design-system";
import { useForm } from "@grape/ui-forms";
import { ErrorWarningLineIcon, MagicLineIcon } from "@grape/ui-icons";

// TODO: we need to move the utils to the `ui-core` package.
const EMAILREGEX = /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/g;

export interface ILoginProps {
  hasFormError?: boolean;
  onEmailSubmit?: (data: LoginFormData) => void;
  onGoogleAuthClick?: () => void;
}

export type LoginFormData = {
  email: string;
};

export const LoginForm: FC<ILoginProps> = ({
  hasFormError,
  onEmailSubmit,
  onGoogleAuthClick,
}) => {
  const methods = useForm<LoginFormData>();
  const { handleSubmit, register } = methods;
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);

  return (
    <main className="flex flex-col items-center w-full max-w-lg">
      <div className="flex flex-row items-start w-full">
        <Image src="/grape_logo_combined_primary.svg" width={120} />
      </div>
      <Box className="mt-4 md:px-12 md:py-12 max-w-full">
        <div className="grid grid-cols-1 w-full gap-4">
          <Typography component="h2" variant="h4" className="mb-4">
            {t("sign-in")}
          </Typography>

          <button
            aria-label={t("sign-in-google")}
            className="py-3 px-4 border rounded-md border-gray-light shadow-sm flex items-center justify-center w-full focus:border-primary focus:ring focus:ring-primary-tint focus:ring-opacity-50"
            type="button"
            onClick={() => onGoogleAuthClick()}
          >
            <Image src="/google.svg" alt="google" height="20" width="20" />
            <p className="text-base font-medium ml-4 text-gray-700">
              {t("sign-in-google")}
            </p>
          </button>
          <div className="w-full flex items-center justify-between py-4">
            <hr className="w-full text-gray-light" />
            <p className="text-base leading-4 px-2.5 text-caption">{t("or")}</p>
            <hr className="w-full text-gray-light" />
          </div>
          <form
            onSubmit={handleSubmit((evt) => {
              setLoading(true);
              onEmailSubmit(evt);
            })}
            className="space-y-4"
          >
            <InputLabelled
              outerClassName="mb-4"
              label={t("email-address")}
              disabled={loading}
              {...register("email", {
                required: t("required"),
                pattern: {
                  value: EMAILREGEX,
                  message: t("expect_email"),
                },
              })}
            />
            <div>
              <Button
                loading={loading}
                label={t("sign-in-email")}
                className="w-full justify-center"
                type="submit"
                fullWidth
              />
              <div className="flex flex-row items-center bg-primary-tint rounded-md text-xs px-4 py-2 mt-2">
                <MagicLineIcon size="1rem" className="mr-2" />
                {t("send-email-message")}
              </div>
              {hasFormError && (
                <div className="flex flex-row items-center justify-center bg-danger-light rounded-md text-xs px-4 py-1 mt-2">
                  <ErrorWarningLineIcon className="mr-2 w-4 h-4" />
                  {t("send-email-message-error")}
                </div>
              )}
            </div>
          </form>
        </div>
      </Box>
    </main>
  );
};

LoginForm.defaultProps = {
  hasFormError: false,
  onEmailSubmit: () => {},
  onGoogleAuthClick: () => {},
};

export default LoginForm;
